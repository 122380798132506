
/**
 * Sorts a list of words by their similarity to a comparand word.
 * @param comparand - The word to compare against.
 * @param words - The list of words to sort.
 * @returns The sorted list of words.
 */
export function sortBySimilarity(comparand: string, words: string[]): string[];

/**
 * Sorts a list of words by their similarity to a comparand word.
 * @param comparand - The word to compare against.
 * @param words - The list of words to sort.
 * @param selector - A function that selects the string to compare from each word.
 * @returns The sorted list of words.
 */
export function sortBySimilarity<T = string>(
    comparand: string, words: T[], selector: (word: T) => string
): T[];

/**
 * implementation function for above overloads
 */
export function sortBySimilarity(
    comparand: string,
    words: any[],
    selector?: (word: any) => string
): any {
    if (selector === undefined) {
        selector = (word: string) => word;
    }

    const wordDistances = words.map(word => ({
        word: word,
        distance: levenshteinDistance(selector(word), comparand)
    }));

    wordDistances.sort((a, b) => a.distance - b.distance);

    // Return the sorted list of words
    return wordDistances.map(wd => wd.word);
}

function levenshteinDistance(w1: string, w2: string): number {
    const l1 = w1?.length ?? 0;
    const l2 = w2?.length ?? 0;
    if (l1 === 0) {
        return l2;
    }

    if (l2 === 0) {
        return l1;
    }

    const distances = new Array(l1 + 1);
    for (let i = 0; i <= l1; i++) {
        distances[i] = new Array(l2 + 1);
    }

    for (let i = 0; i <= l1; i++) {
        distances[i][0] = i;
    }

    for (let j = 0; j <= l2; j++) {
        distances[0][j] = j;
    }

    for (let i = 1; i <= l1; i++) {
        for (let j = 1; j <= l2; j++) {
            if (w1[i - 1] === w2[j - 1]) {
                distances[i][j] = distances[i - 1][j - 1];
                continue;
            }

            distances[i][j] = Math.min(
                distances[i - 1][j],
                distances[i][j - 1],
                distances[i - 1][j - 1]
            ) + 1;
        }
    }

    return distances[l1][l2];
}