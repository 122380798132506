import { useLogger } from '@libs/logger';
import { ServiceName, ServiceFactory } from '@libs/services/service.ts';
import { Deferred, Once } from '@rdt-utils'
import { soundex } from './soundex';
import { sortBySimilarity } from './sortBySimilarity';

const logger = useLogger('meds-lookup-service');

const deferred = new Deferred<{
    soundexIndex: Map<string, ReadonlyArray<Medication>>,
    meds: ReadonlyArray<MedicationLookup>,
    medByBrand: Map<string, Medication>,
    dosageFormList: ReadonlyArray<DosageForm>
    dosageFormMap: Map<number | string, DosageForm>
}>();

const medService = {
    find(value: string, limit: number): Promise<ReadonlyArray<Medication>> {
        return deferred.promise.then(data => {
            const valueProper = value.length > 3 ? value[0].toUpperCase() + value.slice(1) : value;
            return findMatches(valueProper, limit, data);
        }
        );
    },
    getByBrand(brand: string): Promise<Medication> {
        return deferred.promise.then(data => {
            const med = data.medByBrand.get(brand);
            if (!med) {
                throw new Error('Medication not found');
            }
            return med;
        });
    },
    getDosageForms(): Promise<ReadonlyArray<DosageForm>> {
        if (!deferred.isDone) {
            return localDosageForms;
        }
        return deferred.promise
            .then(data => data.dosageFormList)
            .catch(() => localDosageForms);
    }
}

type MedicationLookup = Medication & {
    readonly brandUpper: string,
};

export type Medication = {
    readonly id: string,
    readonly brand: string,
    readonly substance: string,
    dosageForms: () => ReadonlyArray<DosageForm>,
};

export type DosageForm = {
    readonly id: number,
    readonly value: string,
    readonly caption: string,
};

export type MedService = {
    find(value: string, limit: number): Promise<ReadonlyArray<Medication>>;
    getByBrand(brand: string): Promise<Medication>;
    getDosageForms(): Promise<ReadonlyArray<DosageForm>>;
}

export const MED_LOOKUP_SERVICE = ServiceName.create<MedService>('MED_LOOKUP_SERVICE');
export const medServiceFactory = Object.seal({
    name: MED_LOOKUP_SERVICE,
    create: () => medService,
    postInit: async () => {
        loadMedData();
    }
} as ServiceFactory<MedService>);

let retryCount = 0
function loadMedData(delay = 0) {
    setTimeout(async () => {
        fetch('./meds.list.json')
            .then(response => response.json())
            .then(data => {
                const dosageFormMap = new Map<number | string, DosageForm>();
                const forms = data.dosage_form_lookup as Array<{
                    id: number,
                    value: string,
                    caption: string
                }>;
                const dosageFormList = forms.map(f => {
                    const form = Object.freeze(f);
                    dosageFormMap.set(f.id + "", form);
                    dosageFormMap.set(form.id, form);
                    return form;
                }) as ReadonlyArray<DosageForm>;

                const headers = data.headers as string[];
                const idCol = headers.indexOf('id');
                const brandCol = headers.indexOf('brand');
                const substanceCol = headers.indexOf('substance');
                const dosageFormsCol = headers.indexOf('dosage_forms');

                const soundexIndex = new Map<string, Array<Medication>>();
                const medByBrand = new Map<string, Medication>();
                const meds = new Array<MedicationLookup>();
                const rows = data.rows as Array<Array<any>>;
                for (const row of rows) {
                    const id = row[idCol] as number;
                    const brand = row[brandCol] as string;
                    const substance = row[substanceCol] as string;
                    const dosageForms = Once.of(() => {
                        const formIds = row[dosageFormsCol] as string;
                        return formIds.split(',').map(id => dosageFormMap.get(id.trim())!);
                    });

                    const m = Object.freeze({ id: id + "", brand, substance, dosageForms, brandUpper: brand.toUpperCase() });
                    medByBrand.set(brand, m)
                    meds.push(m);

                    // TODO: add soundex code during the data set generation
                    if (brand.length < 15) {
                        const space = brand.indexOf(' ');
                        if (space === -1 || brand.indexOf(' ', space + 1) === -1) {
                            const sidx = soundex(brand[0].toUpperCase() + brand.slice(1));
                            const existing = soundexIndex.get(sidx);
                            if (existing) {
                                existing.push(m);
                                existing.sort((a, b) => a.brand.localeCompare(b.brand));
                            } else {
                                soundexIndex.set(sidx, [m]);
                            }
                        }
                    }
                }

                const result = {
                    soundexIndex: Object.freeze(soundexIndex),
                    meds: Object.freeze(meds),
                    dosageFormList: Object.freeze(dosageFormList),
                    dosageFormMap,
                    medByBrand
                };

                deferred.resolve(result);
                logger.info('meds loaded');
            })
            .catch(err => {
                if (retryCount < 3) {
                    retryCount++;
                    loadMedData(retryCount * 1000);
                } else {
                    logger.error('meds load error', err);
                    deferred.reject(err);
                }
            });
    }, delay);
}

const localDosageForms = Promise.resolve([
    { "id": 1, "value": "aerosolSpray", "caption": "Aerosol or Spray" }, { "id": 2, "value": "drops", "caption": "Drops" },
    { "id": 3, "value": "implantInsert", "caption": "Implant or Insert" }, { "id": 4, "value": "inhaler", "caption": "Inhaler" },
    { "id": 5, "value": "injectable", "caption": "Injectable" }, { "id": 6, "value": "kit", "caption": "Kit" },
    { "id": 7, "value": "liquid", "caption": "Liquid" }, { "id": 8, "value": "lozengeChewable", "caption": "Lozenge or Chewable" },
    { "id": 0, "value": "Other", "caption": "Other" }, { "id": 9, "value": "patch", "caption": "Patch" },
    { "id": 10, "value": "powderGranule", "caption": "Powder or Granule" }, { "id": 11, "value": "suppository", "caption": "Suppository" },
    { "id": 12, "value": "system", "caption": "System" }, { "id": 13, "value": "tablet", "caption": "Tablet or Capsule" },
    { "id": 14, "value": "topical", "caption": "Topical" }
] as DosageForm[]);

const findMatches = (valueProper: string, limit: number, data: {
    soundexIndex: Map<string, ReadonlyArray<Medication>>,
    meds: ReadonlyArray<MedicationLookup>
}) => {
    const valueUpper = valueProper.toUpperCase();
    const result = new Array<Medication>();
    for (const med of data.meds) {
        if (med.brandUpper.startsWith(valueUpper) || med.substance.startsWith(valueUpper)) {
            result.push(med);
        }
        if (result.length >= limit) {
            return result;
        }
    }

    if (valueProper.length >= 3) {
        const soundexValue = soundex(valueProper);
        const soundexMatches = data.soundexIndex.get(soundexValue);
        if (soundexMatches) {
            for (const med of soundexMatches) {
                result.push(med);
                if (result.length >= limit) {
                    return result;
                }
            }
        }
    }

    const contains = new Array<Medication>();
    for (const med of data.meds) {
        if (med.brandUpper.startsWith(valueUpper) || med.substance.startsWith(valueUpper)) {
            continue; // will already have been added
        }

        if (med.brandUpper.includes(valueUpper) || med.substance.includes(valueUpper)) {
            contains.push(med);
        }

        if (result.length + contains.length >= limit) {
            break;
        }
    }

    const sortedContains = sortBySimilarity(valueProper, contains, m => m.brand);

    result.push(...sortedContains);

    return result;
};