import { appStorage } from "./app.storage";
import { LogLevel, logger } from "./logger";
import { utils } from "../host";
import { ServiceFactory, ServiceName } from "@libs/services/service.ts";

const CORDOVA_HEIGHT_STORAGE_KEY = 'cordova-screen-orientation-innerHeight';

// TODO:
//   Refactor or remove once this has been addressed at a platform level, etc.
//
// NOTE:
//   Much of the below is to address the spacing and the subsequent timing of the
//   sequence of when to re-orient, setup the status bar, and hide the splashscreen

export type Cordova = {
    isCordovaApp: () => boolean;
    CORDOVA_HEIGHT_STORAGE_KEY: string;
}

let preInitRun = false;
let hasRun = false;
let hasOrientationLockRun = false;

export const Cordova = Object.freeze({
    isCordovaApp: () => utils.isCordovaApp(),
    CORDOVA_HEIGHT_STORAGE_KEY: CORDOVA_HEIGHT_STORAGE_KEY
});

export const CORDOVA_SERVICE = ServiceName.create<Cordova>('CORDOVA_SERVICE');
export const cordovaServiceFactory: ServiceFactory<Cordova> = Object.seal({
    name: CORDOVA_SERVICE,
    create: () => {
        if (preInitRun) {
            return Cordova;
        }

        logger.emit(LogLevel.ERROR, "[cordova::init] preInit() not called");
        throw new Error("[cordova::init] preInit() not called");
    },
    init: async () => {
        if (!utils.isCordovaApp()) {
            logger.emit(LogLevel.DEBUG, "[cordova::init] not a cordova app");
            return;
        }

        if (preInitRun) {
            logger.emit(LogLevel.DEBUG, `[cordova::init] preInit() hasRun: ${hasRun}`);
            return;
        }

        preInitRun = true;

        window.addEventListener('resize', () => {
            const type = window.screen?.orientation?.type;
            logger.emit(LogLevel.DEBUG, `[resize] screen.orientation.type: ${type}, hasOrientationLockRun: ${hasOrientationLockRun}, window.innerHeight: ${window.innerHeight}`);
        });

        await new Promise<void>(resolve => {
            const heightChanges = () => handleHeightChanges(resolve);
            if (hasRun) {
                logger.emit(LogLevel.DEBUG, "[cordova::init] hasRun: true");
                return heightChanges();
            }

            document.addEventListener('deviceready', () => {
                logger.emit(LogLevel.DEBUG, "[cordova::init] hasRun: false");
                hasRun = true;
                handleStatusbarAndSplashScreen(heightChanges);
            }, false);
        });

        setTimeout(storeDimensions, 1000);
    }
});

function handleHeightChanges(cb: (() => void) | null) {
    const doCallback = () => {
        if (cb) {
            const c = cb;
            cb = null;
            c();
        }
    };

    try {
        const current = Number(appStorage.getItem(CORDOVA_HEIGHT_STORAGE_KEY) || 0);
        if (Number.isNaN(current) || current === 0 || (window.innerHeight < current && Math.abs(window.innerHeight - current) > 10)) {
            rotate(current, doCallback);
        } else {
            logger.emit(LogLevel.DEBUG, `[else::handleHeightChanges] cordova-screen-orientation-innerHeight: ' + ${current}, window.innerHeight: ${window.innerHeight}`);
            doCallback();
        }
    } catch (e: any) {
        logger.emit(LogLevel.ERROR, '[handleHeightChanges] ERROR: ' + e);
        doCallback();
    }
}

function handleStatusbarAndSplashScreen(cb: () => void) {
    try {
        const statusBar = (window as any).StatusBar;
        if (!statusBar) {
            logger.emit(LogLevel.ERROR, '[handleStatusbarAndSplashScreen] Native StatusBar not found');
        } else {
            statusBar.overlaysWebView(true);
            statusBar.styleDefault();
            // TODO: make this dynamic based on theme, etc
            statusBar.backgroundColorByHexString("#C0C0C0");
        }
    } catch (e) {
        logger.emit(LogLevel.ERROR, '[handleStatusbarAndSplashScreen] while updating native status bar settings: ' + e);
    } finally {
        cb();
    }

    // Add a class to the body to identify that the app is running in Cordova
    // was moved to index.html
    //document.body.classList.add('cordova-app');
}

function rotate(current: number, cb: (() => void) | null) {
    logger.emit(LogLevel.DEBUG, `[rotate] hasOrientationLockRun: ${hasOrientationLockRun}, cordova-screen-orientation-innerHeight: ${current}, window.innerHeight: ${window.innerHeight}`);
    hasOrientationLockRun = true;

    const doCallback = () => {
        if (cb) {
            const c = cb;
            cb = null;
            c();
        }
    };

    if (!window.screen.orientation.type.startsWith("portrait")) {
        lockSafe('portrait');
        return doCallback();
    }

    lockSafe('landscape');
    setTimeout(() => {
        lockSafe('portrait');
        doCallback();
    }, 500);
}

function lockSafe(mode: string) {
    function unlockSafe() {
        try {
            logger.emit(LogLevel.DEBUG, `[unlockSafe] [innerheight - ${window.innerHeight}] BEFORE: unlock()`);
            (window.screen.orientation as any).unlock();
            logger.emit(LogLevel.DEBUG, `[unlockSafe] [innerheight - ${window.innerHeight}] AFTER: unlock()`);
        } catch (e) {
            logger.emit(LogLevel.ERROR, `[unlockSafe] [innerheight - ${window.innerHeight}] ERROR: unlock()`);
        }
    }

    unlockSafe();

    try {
        logger.emit(LogLevel.DEBUG, `[lockSafe] [innerheight - ${window.innerHeight}] BEFORE: lock('${mode}')`);
        (window.screen.orientation as any).lock(mode);
        logger.emit(LogLevel.DEBUG, `[lockSafe] [innerheight - ${window.innerHeight}] AFTER: lock('${mode}')`);
    } catch (e) {
        logger.emit(LogLevel.ERROR, `[lockSafe] [innerheight - ${window.innerHeight}] ERROR: lock('${mode}')`);
    }
}

function storeDimensions() {
    // if current height is larger than stored height, then store current height
    // if current height is smaller than stored height, then do nothing
    try {
        const type = window.screen?.orientation?.type;
        if (!type) {
            logger.emit(LogLevel.DEBUG, `[storeDimensions] screen.orientation.type: NO_TYPE, hasOrientationLockRun: ${hasOrientationLockRun}, window.innerHeight: ${window.innerHeight}`);
            return;
        }

        logger.emit(LogLevel.DEBUG, `[storeDimensions] hasOrientationLockRun: ${hasOrientationLockRun}, screen.orientation.type:  + ${type}, window.innerHeight: ${window.innerHeight}`);
        if (type.startsWith("portrait")) {
            const current = Number(appStorage.getItem(CORDOVA_HEIGHT_STORAGE_KEY) || 0);
            if (Number.isNaN(current) || current === 0 || window.innerHeight > current) {
                logger.emit(LogLevel.DEBUG, "[storeDimensions] appStorage.screen.height: " + String(window.innerHeight));
                appStorage.setItem(CORDOVA_HEIGHT_STORAGE_KEY, String(window.innerHeight));
            }
        }
    } catch (e: any) {
        logger.emit(LogLevel.ERROR, '[storeDimensions] ERROR: ' + e);
    }
}