/**
 * Converts a word to its Soundex representation.
 * @param word - The word string to convert.
 * @param expectedLength - The expected length of the Soundex result -- minimum is 4.
 * @returns The Soundex representation of the provided word.
 */
export function soundex(word: string, expectedLength = 4) {
    assert(word && word.length > 0, '`value` must be a non-empty string');
    assert(expectedLength && expectedLength >= 4, '`expectedLength` must be >= 4');

    let result = word.charAt(0).toUpperCase();
    let previous = getPhonetic(word.charCodeAt(0))

    for (let i = 1; i < word.length && result.length < expectedLength; i++) {
        const p = getPhonetic(word.charCodeAt(i))
        if (p && p !== previous) {
            result += (previous = p);
        } else if (p === VOWEL_PHONETIC) {
            previous = undefined // Vowel
        }
    }

    return result.padEnd(expectedLength, '0')
}

const VOWEL_PHONETIC = 0;
const getPhonetic = (() => {
    const m = new Uint8Array(26);

    m[0] = m[4] = m[7] = m[8] = m[14] = m[20] = m[22] = m[24] = 0; // A, E, H, I, O, U, W, Y
    m[1] = m[5] = m[15] = m[21] = 1; // B, F, P, V
    m[2] = m[6] = m[9] = m[10] = m[16] = m[18] = m[23] = m[25] = 2; // C, G, J, K, Q, S, X, Z
    m[3] = m[19] = 3; // D, T
    m[11] = 4; // L
    m[12] = m[13] = 5; // M, N
    m[17] = 6; // R

    const upperBitMask = 223;

    return (charCode: number) => {
        // flip the 6th bit to make it uppercase and subtract 65 to get the index
        const idx = (upperBitMask & charCode) - 65;

        // retreive phonetics value
        return m[idx]
    }
})() as (charCode: number) => number | undefined;

// deno-lint-ignore no-explicit-any
function assert(condition: any, message: string) {
    if (!condition) {
        throw new Error(message);
    }
}