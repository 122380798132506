import { logger } from '@libs/logger/mod.ts';
import { Config } from '@libs/config/index.ts';
import { Env } from '@libs/environment/index.ts';

export const APP_ORIGIN = Env.global.location.origin as string;
const SAME_ORIGIN_MAP_FN = (value: string) => {
    return !value || value === 'same_origin' ? APP_ORIGIN : value;
}

export const APP_API_ORIGIN = Config.API_URL.str().map(SAME_ORIGIN_MAP_FN).else(APP_ORIGIN);

export const AUTH0_ALLOWED_AUTH_TYPES = Object.freeze(Config.AUTH0_ALLOWED_AUTH_TYPES.array(['sms', 'email']).get());

logger.info('Using api url from origin ' + APP_API_ORIGIN);

// auth0 assigned role names
export const ROLES = {
    DEBUG_USERS: 'DEBUG_USERS',
    APP_DEVELOPERS: 'APP_DEVELOPERS',
    INTERNAL_USERS: 'INTERNAL_USERS',
}