//TODO: seperate concerns - dynamic colors vs static layout attributes
import { createTheme } from '@suid/material';

import { appStorage } from '@app/utils/app.storage.ts';

export function getCurrentTheme() {
    return careCardThemes[appStorage.getItem('currentThemeName') ?? 'calmOcean']; // default theme if the provided theme name doesn't exist
}

export function setCurrentTheme(themeName: string) {
    appStorage.setItem('currentThemeName', themeName);
}

export function getThemeOrDefault(themeName: string) {
    return careCardThemes[themeName] ?? getCurrentTheme();
}

//careCardThemes
export const careCardThemes: { [key: string]: any } = {
    calmOcean: {
        primary: 'hsl(187deg, 33%, 49%)',
        secondary: 'hsl(187deg, 32%, 7%)',
        iconPrimary: 'hsl(187deg, 33%, 80%)',
        iconSecondary: 'hsl(187deg, 32%, 7%)',
        textPrimary: 'hsl(187deg, 32%, 7%)',
        textSecondary: 'hsl(187deg, 32%, 7%)',
    },
    desertMoon: {
        primary: 'hsl(173deg, 33%, 49%)',
        secondary: 'hsl(173deg, 32%, 7%)',
        iconPrimary: 'hsl(173deg, 33%, 80%)',
        iconSecondary: 'hsl(173deg, 32%, 7%)',
        textPrimary: 'hsl(173deg, 32%, 7%)',
        textSecondary: 'hsl(173deg, 32%, 7%)',
    },
    rhythmicRadiance: {
        primary: 'hsl(187deg, 23%, 61%)',
        secondary: 'hsl(187deg, 32%, 6%)',
        iconPrimary: 'hsl(187deg, 33%, 80%)',
        iconSecondary: 'hsl(187deg, 32%, 6%)',
        textPrimary: 'hsl(187deg, 32%, 6%)',
        textSecondary: 'hsl(187deg, 32%, 6%)',
    },
    organicOdyssey: {
        primary: 'hsl(150deg, 23%, 61%)',
        secondary: 'hsl(150deg, 32%, 6%)',
        iconPrimary: 'hsl(150deg, 33%, 80%)',
        iconSecondary: 'hsl(150deg, 32%, 6%)',
        textPrimary: 'hsl(150deg, 32%, 6%)',
        textSecondary: 'hsl(150deg, 32%, 6%)',
    },
};

/**
 * Title: Compile and IDE slowness issues
 * Author: Randal
 * Date: 2023-10-17
 * Detail: The below is a workaround for a bug in the @suid/material package.
 * The bug is that the createTheme function at compilation time causes
 * a type check that takes 2-3 minutes. Additionally, it eventually just
 * recurses until thr depth limit fails and doesn't finish, so it is not
 * actually helping in anyway, only makign the DIE and compiles times
 * PAINFULLY slow.
 *
 * You can replace the below with the original createTheme and then run
 * 'tsc --generateTrace --extendedDiagnostics ts-trace' to see the check
 * time issue. Additionally, run 'npx analyze-trace ts-trace' to see
 * the breakdown.
 */
const doNotCheckCreateTheme = createTheme as any;
export const theme = doNotCheckCreateTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: getCurrentTheme().primary,
        },
        secondary: {
            main: getCurrentTheme().secondary,
        },
        text: {
            primary: getCurrentTheme().textPrimary,
            secondary: getCurrentTheme().textSecondary,
        },
    },
    typography: {
        fontFamily: 'Open Sans',
    },
    components: {
        /* MuiCssBaseline: {
          styleOverrides: {
            html: {
              background: "#FF0000"
          },
        },
      }, */
        MuiTextField: {
            defaultProps: {
                InputProps: {
                    disableUnderline: true,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                disableUnderline: true,
            },
        },
    },
});
