
//TODO: seperate concerns - dynamic colors vs static layout attributes
import { Theme } from '@suid/material';
import { SxProps } from '@suid/system';

import TexturePrimary from './textures/TexturePrimary.tsx';
import TextureWhite from './textures/TextureWhite.tsx';
import { zIndexes } from '@app/assets/zIndexes.ts';
import { getCurrentTheme as currentTheme, getThemeOrDefault as themeOrDefault } from './theme.ts';

import {
    Switch
} from '@suid/material';

import { theme } from '@app/assets/theme';
import { styled } from '@suid/material/styles';

const texturePrimary = TexturePrimary;
const textureWhite = TextureWhite;

export function getCurrentTheme() {
    return currentTheme();
}

export function getThemeOrDefault(themeName: string) {
    return themeOrDefault(themeName);
}

//Home and Interior Page Layout Styles
export const layoutHeaderStyles: Record<string, SxProps<Theme>> = {
    layoutHeaderContainer: {
        background: getCurrentTheme().primary,
        backgroundImage: 'url(' + textureWhite + ')',
        backgroundRepeat: 'repeat',
        backgroundSize: { xs: '100%' },
        inset: '0px',
        position: 'absolute',
    },
    layoutHeaderContainerInterior: {
        background: getCurrentTheme().primary,
        backgroundImage: 'url(' + textureWhite + ')',
        backgroundRepeat: 'repeat',
        backgroundSize: { xs: '100%' },
        inset: '0px',
        overflow: 'auto',
        position: 'fixed',
    },
    actionMenu: {
        top: '20px',
        position: 'fixed',
        right: '30px',
        cursor: 'pointer',
        '.cordova-app &': {
            top: '60px',
        },
    }
};
export const layoutBodyStyles: Record<string, SxProps<Theme>> = {
    layoutBodyContainer: {
        top: { xs: '230px' },
        borderTopLeftRadius: { xs: '0px' },
        borderTopRightRadius: { xs: '0px' },
        bottom: '0',
        right: '0',
        left: '0',
        overflow: 'auto',
        position: 'absolute',
        marginLeft: { xs: '0px' },
        marginRight: { xs: '0px' },
        backgroundImage: 'url(' + texturePrimary + ')',
        backgroundRepeat: 'repeat',
        backgroundColor: '#FFFFFF',
        backgroundSize: { xs: '100%' },
        marginTop: { xs: '0px' },
        '.cordova-app &': {
            top: '270px',
        },
    },
    featureListingContainer: {
        marginTop: { xs: '35px' },
        marginLeft: { xs: '15px' },
        marginRight: { xs: '10px' },
    },
    layoutBodyContainerInterior: {
        top: { xs: '35px', },
        borderTopLeftRadius: { xs: '30px' },
        borderTopRightRadius: { xs: '30px' },
        bottom: '0',
        right: '0',
        left: '0',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        marginLeft: { xs: '0px' },
        marginRight: { xs: '0px' },
        overflow: 'hidden',
        '.cordova-app &': {
            top: '105px',
        },
    },
    formHeaderContainerEditMode: {
        display: 'flex',
        marginTop: { xs: '30px' },
        marginLeft: { xs: '20px' },
        alignItems: 'center',
        justifyContent: 'space-between',
        '.cordova-app &': {
            top: '105px',
        },
    },
    pageTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    backArrowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        paddingRight: '5px',
    },
    backAction: {
        bgcolor: '#182325',
        marginTop: '20px',
        marginLeft: '10px',
        '.cordova-app &': {
            marginTop: '60px',
        },
    },
    saveAction: {
        boxShadow: 'none',
        position: 'absolute',
        top: 20,
        right: 20,
        borderRadius: 1,
        paddingLeft: 2,
        paddingRight: 2,
        '.cordova-app &': {
            marginTop: '0px',
        },
    },
    bottomAction: {
        boxShadow: 'none',
        width: '88dvw',
        borderRadius: 1,
        paddingLeft: 2,
        paddingRight: 2,
        padding: 3,
        textTransform: 'none',
    },
    pageTitle: {
        paddingBottom: '5px',
    },
    actionSave: {
        marginRight: { xs: '25px' },
        paddingBottom: '5px',
    },
    formContainer: {
        overflow: 'auto',
        height: '90dvh',
        margin: '20px',
    },
    formBox: {
        width: '88dvw'
    },
    callToAction: {
        position: 'fixed',
        top: '200px',
        zIndex: zIndexes.appBar + 1,
        left: '5px',
        textTransform: 'none',
        '.cordova-app &': {
            top: '240px',
        },
    }
};
const careCardPhotoFrameZIndex = zIndexes.appBar - 1;

export const careCardPhotoStyles: Record<string, SxProps<Theme>> = {
    careCardPhotoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: { xs: 'center' },
        alignItems: { xs: 'center' },
        width: '100%',
        overflow: 'hidden',
        minHeight: { xs: '245px' },
        marginTop: { xs: '-25px' },
        '.cordova-app &': {
            marginTop: '-25px',
        },
    },
    careCardPhotoFrame: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 5,
        borderColor: '#EBEBEB',
        height: { xs: '230px', md: '230px' },
        width: { xs: '230px', md: '230px' },
        minHeight: { xs: '230px', md: '230px' },
        minWidth: { xs: '230px', md: '230px' },
        marginLeft: { xs: '0px', md: '0px' },
    },
    smallCardNext: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 3,
        borderColor: '#EBEBEB',
        height: { xs: '115px' },
        width: { xs: '115px' },
        minHeight: { xs: '115px' },
        minWidth: { xs: '115px' },
        marginLeft: { xs: '-10px' },
        zIndex: careCardPhotoFrameZIndex - 1,
        cursor: 'pointer',
        opacity: '.8',
    },
    smallCardBack: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 3,
        borderColor: '#EBEBEB',
        height: { xs: '115px' },
        width: { xs: '115px' },
        minHeight: { xs: '115px' },
        minWidth: { xs: '115px' },
        marginLeft: { xs: '0px' },
        marginRight: { xs: '-10px' },
        zIndex: careCardPhotoFrameZIndex - 1,
        cursor: 'pointer',
        opacity: '.8',
    },
    editPhoto: {
        zIndex: careCardPhotoFrameZIndex + 1,
        cursor: 'pointer',
    },
    link: {
        zIndex: careCardPhotoFrameZIndex + 1
    }
};
export const careCardIdentityStyles: Record<string, SxProps<Theme>> = {
    careCardIdentityContainer: {
        marginTop: { xs: '60px' },
        textAlign: 'center',
    },
    careCardName: {
        width: { xs: '100dvw' },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: { xs: '28px', },
        fontWeight: '600',
        lineHeight: '1.6',
    },
    careCardAge: {
        alignItems: { xs: 'center' },
        width: { xs: '100dvw' },
        fontSize: { xs: '18px' },
    },
};
export const featureCardStyles: Record<string, SxProps<Theme>> = {
    featureCard: {
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, .4)',
        padding: '8px 5px 8px 5px',
        ':hover': { bgcolor: '#FFFFFF' },
        cursor: 'pointer',
        border: '1px solid #DDDDDD',
    },
    featureCardText: {
        textAlign: 'left',
        fontSize: { xs: '15px' },
        fontWeight: '600',
        width: '80px',
        marginRight: '4px',
        letterSpacing: '-.4px',
    },
};
export const shareCareCardStyles: Record<string, SxProps<Theme>> = {
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundColor: 'primary.main',
        color: '#FFFFFF',
        '.cordova-app &': {
            marginTop: '40px',
        }
    }
};
//Card Interior Layout Styles
export const careCardInteriorPhotoStyles: Record<string, SxProps<Theme>> = {
    careCardInteriorPhotoContainer: {
        justifyContent: { xs: 'center' },
        marginTop: { xs: '40px' },
        '.cordova-app &': {
            marginTop: '110px',
        },
    },
    careCardInteriorPhotoFrame: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 5,
        borderColor: '#EBEBEB',
        height: { xs: '140px' },
        width: { xs: '140px' },
        marginRight: { xs: '20px' },
        zIndex: careCardPhotoFrameZIndex - 1,
    },
};
export const careCardInteriorIdentityStyles: Record<string, SxProps<Theme>> = {
    careCardInteriorIdentityContainer: {
        marginTop: { xs: '60px' },
        textAlign: 'center',
    },
    careCardInteriorName: {
        width: { xs: '180px' },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: { xs: '18px' },
        fontWeight: '400',
        lineHeight: '1.3',
    },
    careCardInteriorPageTitle: {
        width: { xs: '180px' },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: { xs: '24px' },
        fontWeight: '500',
    }
};

export const documentStyles: Record<string, SxProps<Theme>> = {
    documentForm: {
        fontSize: { xs: '14px' },
    },
    documentFormLabel: {
        fontWeight: '500',
        fontSize: { xs: '12px' },
        opacity: '.6',
    },
    documentFormValue: {
        marginBottom: '10px',
        whiteSpace: 'pre-wrap',
    },
    documentFormValueAttention: {
        marginBottom: '10px',
        whiteSpace: 'pre-wrap',
        fontSize: { xs: '18px' },
        color: '#E41010'
    }
};

// Moved here due to a cyclic import issues nested within SUID
export const EmergencyContactSwitch: any = styled(Switch)(() => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#FAE5E1' : '#F08B8B',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));