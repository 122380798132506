import { render } from 'solid-js/web';
import { JSX } from 'solid-js';
import { Pending } from '@app/components/Pending.tsx';
import { LogLevel, useLogger } from './utils/logger.ts';

const logger = useLogger('APP');

export const app = {
    start: (children: () => JSX.Element, fallback?: () => JSX.Element) => {
        assertNotStarted();

        logger.emit(LogLevel.DEBUG, "window.navigator.userAgent: " + window.navigator.userAgent);

        const init = import('./app.services').then(m => m.loadServices()).catch(onLoadError);
        const appUI = () => Pending({
            init,
            children,
            fallback
        });

        render(appUI, getRoot());
    }
};

const onLoadError = (err: any) => {
    logger.error('Failed to initialize app', err);
    // TODO: surface this up to the user
    throw new Error('Failed to initialize app');
}

const getRoot = () => {
    const root = document.getElementById('root');
    if (!root || ((import.meta as any).env.DEV && !(root instanceof HTMLElement))) {
        throw new Error(
            'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
        );
    }

    return root;
};

const assertNotStarted = (() => {
    let started = false;
    return () => {
        if (started) {
            logger.emit(LogLevel.ERROR, 'App already started');
            throw new Error('App already started');
        }
        started = true;
    }
})();